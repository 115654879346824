import * as React from 'react'
import { FunctionComponent, PropsWithChildren } from 'react'
import * as Ariakit from '@ariakit/react'

type DropdownDialogProps = {
  button: FunctionComponent<{ onClick: () => void }>
  setOption: (input: any) => void
  options: { label: string; name: string }[]
}

const DropdownDialog: FunctionComponent<
  PropsWithChildren<DropdownDialogProps>
> = ({ button, setOption, options }) => {
  const store = Ariakit.useDialogStore()
  const ToggleBotton = button

  const handleSelection = (value) => {
    store.hide()
    setOption(value)
  }

  return (
    <>
      <ToggleBotton onClick={() => store.show()} />
      <Ariakit.Dialog
        store={store}
        hideOnInteractOutside={true}
        hideOnEscape={true}
        backdrop={
          <div className='fixed inset-0 bg-black opacity-50 flex justify-center items-center' />
        }
        className='fixed z-50 inset-3 max-w-fit max-h-fit overflow-y-auto m-auto'
      >
        <div
          className='bg-alpha-oatmeal-80 max-w-[95vw] min-w-80 max-h-[80vh] lg:max-h-[60vh] rounded-2xl text-xl lg:text-2xl border border-solid border-alpha-oatmeal-40 shadow-xl py-2 px-3 overflow-auto font-body font-bold flex flex-col gap-4 text-alpha-oatmeal-10'
          onClick={() => store.hide()}
        >
          {options.map(({ label, name }, idx) => {
            return (
              <React.Fragment key={`${name}${idx}`}>
                <div
                  role='button'
                  className='px-2 md:px-4 py-2 md:py-3 cursor-pointer hover:text-alpha-white'
                  onClick={() => handleSelection({ label, name })}
                  data-qa-name={'button-promoteTabItem-' + name}
                >
                  {label}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </Ariakit.Dialog>
    </>
  )
}

export default DropdownDialog
