import * as React from 'react'
import { FunctionComponent } from 'react'
import { BsBadgeCcFill as ClosedCaptionsIcon } from 'react-icons/bs'

export const VideoPlayerModalDownloadHelperText: FunctionComponent = () => {
  return (
    <div className='flex items-start gap-4 p-4 bg-alpha-oatmeal-80 rounded text-alpha-white'>
      <ClosedCaptionsIcon className='h-[40px] w-[40px] flex-none' />
      <div className='flex flex-col gap-6 items-start'>
        <label
          className='text-sm '
          data-qa-name='label-bcVideoPlayerHelperText'
        >
          {$localize`:brightcoveSubtitleDownload.label|Subtitle Download helper@@SubtitleDownloadHelper:NEW! Multi-lingual subtitles are built into this video. Use VLC Player for best results.`}
        </label>
        <a
          className='font-medium text-sm underline text-linkBlue'
          href='./subtitles'
          target='_blank'
          data-qa-name='link-bcHelperTextLink'
        >
          {$localize`:brightcoveSubtitleDownload.link|Subtitle Download helper link@@SubtitleDownloadHelperLink:Click to See How This Works`}
        </a>
      </div>
    </div>
  )
}

export default VideoPlayerModalDownloadHelperText
