import * as React from 'react'
import { FunctionComponent, useEffect } from 'react'
import * as Ariakit from '@ariakit/react'
import { Button } from '@alpha-international/alpha-ui'

type ErrorNotificationProps = {
  error: string | { message: string; stack: string }
  showDialog?: number
  unmount?: () => void
}

const ErrorNotification: FunctionComponent<ErrorNotificationProps> = (
  props: ErrorNotificationProps,
  unmount
) => {
  const { error, showDialog } = props
  const dialog = Ariakit.useDialogStore()

  useEffect(() => {
    console.error({ error })
    dialog.show()
  }, [])

  useEffect(() => {
    dialog.show()
  }, [showDialog])

  const closeDialog = () => {
    dialog.hide()
  }

  return (
    <Ariakit.Dialog
      store={dialog}
      hideOnInteractOutside={false}
      backdrop={
        <div
          className='fixed inset-0 bg-black opacity-50'
          onMouseDown={closeDialog}
        />
      }
      className='fixed z-50 inset-3 w-5/6 max-w-screen-lg overflow-y-auto text-black inset-y-[5vh] mx-auto flex justify-center items-center'
    >
      <div className='bg-alpha-white shadow-md rounded-lg px-4 py-6 flex flex-col gap-4'>
        <div className='flex flex-col gap-1'>
          <p className='font-display font-medium text-xl my-0'>
            {$localize`:common.words|Common Words - Error@@commonWordsError:Error`}
          </p>
          {error && typeof error === 'string' ? (
            <p className='font-body text-lg m-0'>{error}</p>
          ) : null}
          {(error as any)?.message ? (
            <p className='font-body text-lg m-0'>{(error as any).message}</p>
          ) : null}
        </div>
        <Button onClick={closeDialog} className='text-lg'>
          {$localize`:snackbar.dismiss|Snackbar Dismiss@@SnackbarDismissButton:Dismiss`}
        </Button>
      </div>
    </Ariakit.Dialog>
  )
}

export default ErrorNotification
