import * as React from 'react'
import { FunctionComponent, useEffect, useContext } from 'react'
import * as Ariakit from '@ariakit/react'

import { IoCloseSharp as CloseIcon } from 'react-icons/io5'
import { Button } from '@alpha-international/alpha-ui'
import { IoArrowBackOutline as BackIcon } from 'react-icons/io5'
import PDFPreview from '../pdf-preview/pdf-preview'
import { PiDownloadSimpleBold } from 'react-icons/pi'
import ResourceList from '../resource-list'
import { VideoPlayerModalContext } from '../context'
import { useMediaQuery } from 'react-responsive'
interface PDFPreviewModalProps {
  attachment: any
}
const PDFPreviewModal: FunctionComponent<PDFPreviewModalProps> = ({
  attachment
}) => {
  if (!attachment?.attachedDocument?.url && !attachment?.discussionGuide.url) {
    return null
  }

  const context =
    useContext(VideoPlayerModalContext) ||
    ({} as {
      parentVideoTitle?: string
      relatedDocuments?: any[]
      backToParentVideo?: () => void
    })
  const { parentVideoTitle, relatedDocuments, backToParentVideo } = context
  const documentSource =
    attachment?.attachedDocument || attachment?.discussionGuide

  const { url, filesize, file } = documentSource
  const { title, description } = attachment
  const descriptionText = (
    <p className='my-0 text-base font-body leading-normal'>{description}</p>
  )
  const isMobile = useMediaQuery({ maxWidth: '640px' })
  const dialog = Ariakit.useDialogStore()
  const toggleVisibility = () => {
    dialog.hide()
  }

  useEffect(() => {
    dialog.show()
  }, [])

  const handleDismiss = (event) => {
    if (event.button === 0) {
      dialog.hide()
    }
  }

  const handleDownload = () => {
    if (url) {
      const link = document.createElement('a')
      link.href = url
      link.download = title
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      console.log('file not found')
    }
  }

  return (
    <Ariakit.Dialog
      store={dialog}
      hideOnInteractOutside={false}
      backdrop={
        <div
          className='fixed inset-0 bg-black opacity-50'
          onMouseDown={handleDismiss}
        />
      }
      className='fixed z-50 inset-3 w-5/6 max-w-screen-lg overflow-y-auto max-h-[90vh] bg-white text-black inset-y-[5vh] mx-auto flex justify-stretch react-video-modal h-fit overflow-x-hidden'
    >
      <div className='w-full relative'>
        <div
          className='cursor-pointer absolute z-10 right-4 top-4 md:right-6 md:top-6 lg:right-12 lg:top-12 p-2 text-black'
          onClick={toggleVisibility}
        >
          <CloseIcon className='fill-black text-3xl' />
        </div>

        <div className='p-6 lg:p-10 flex flex-col justify-stretch gap-4 md:gap-6 h-auto box-border'>
          <PDFPreview attachmentUrl={url} />
          <div className='flex flex-col justify-between lg:flex-row gap-6 sm:gap-8'>
            <div className='flex-2 flex flex-col gap-4 justify-between min-w-0'>
              <div className='flex flex-col gap-6'>
                <div className='flex flex-col sm:flex-row justify-between items-start gap-6'>
                  <div className='flex flex-col gap-2'>
                    {backToParentVideo && (
                      <a
                        className='flex gap-1 items-center font-semibold text-alpha-navy hover:text-alpha-navy-140 transition-colors leading-8 cursor-pointer'
                        onClick={backToParentVideo}
                      >
                        <BackIcon className='text-xl' />
                        <span>{parentVideoTitle}</span>
                      </a>
                    )}
                    <h1 className='my-0 leading-8'>{title}</h1>
                    <small className='font-semibold text-gray-400'>
                      {filesize ? filesize : ''}
                      {filesize && file ? '  /  ' : ''}
                      {file ? file : ''}
                    </small>
                    {!isMobile && description ? descriptionText : null}
                  </div>
                  {isMobile && description ? descriptionText : null}
                  <Button
                    onClick={handleDownload}
                    className='flex gap-1 justify-center h-fit text-base items-center border-0 cursor-pointer hover:bg-alpha-red-140 max-w-full sm:max-w-max w-full'
                  >
                    <PiDownloadSimpleBold size={'1.5rem'} color='white' />
                    {$localize`:common.words|Common Words - Download@@commonWordsDownload:Download`}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {relatedDocuments && relatedDocuments.length > 0 && (
          <div className='lg:p-10 p-6 flex flex-col bg-[#EFEBE9]'>
            <ResourceList
              title={$localize`:resourceList.title|ResourceListTitle@@resourceListTitle:Resources For This Session`}
              resources={relatedDocuments}
            />
          </div>
        )}
      </div>
    </Ariakit.Dialog>
  )
}

export default PDFPreviewModal
