import * as React from 'react'
import { FunctionComponent, useEffect, useRef, useState } from 'react'

import { Document, Page, pdfjs } from 'react-pdf'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import useResizeObserver from '@react-hook/resize-observer'

// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === 'undefined') {
  if (window)
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = function () {
      let resolve, reject
      const promise = new Promise((res, rej) => {
        resolve = res
        reject = rej
      })
      return { promise, resolve, reject }
    }
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

interface PDFPreviewProps {
  attachmentUrl: string
}

const PDFPreview: FunctionComponent<PDFPreviewProps> = ({ attachmentUrl }) => {
  const wrapperDiv = useRef<HTMLDivElement>(null)
  const pageDiv = useRef<HTMLDivElement>(null)
  const customArrowButtonStyles =
    'rounded-full w-10 h-10 flex flex-wrap content-center place-content-center bg-white transition-colors text-2xl border-none'
  const customArrowButtonActiveStyles =
    'cursor-pointer hover:bg-gray-100 border-alpha-oatmeal-30 hover:border-alpha-oatmeal-60 focused:border-alpha-oatmeal-60 text-alpha-black'
  const customArrowButtonDisabledStyles =
    'cursor-default border-alpha-oatmeal-10 text-alpha-oatmeal-10'

  const [isReady, setIsReady] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState(true)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [width, setWidth] = useState<number | null>(null)
  const [height, setHeight] = useState<number | null>(null)

  const throttleRef = useRef<NodeJS.Timeout | null>(null)

  const setWidthAndHeight = ({ target }) => {
    if (
      target.current &&
      target.current?.getElementsByClassName('react-pdf__Page__canvas').length
    ) {
      const { height: canvasHeight, width: canvasWidth } =
        target.current?.getElementsByClassName(
          'react-pdf__Page__canvas'
        )[0] as HTMLCanvasElement

      const { clientWidth } = wrapperDiv.current

      const aspectRatio = canvasWidth / canvasHeight
      if (clientWidth / aspectRatio > window.innerHeight * 0.75) {
        setWidth(null)
        setHeight(window.innerHeight * 0.6)
      } else {
        setWidth(clientWidth)
        setHeight(null)
      }
    } else {
      setWidth(null)
      setHeight(null)
    }
  }

  useResizeObserver(wrapperDiv, (entry) => {
    if (throttleRef.current) {
      clearTimeout(throttleRef.current)
      setIsReady(true)
    }
    setIsReady(false)

    throttleRef.current = setTimeout(() => {
      setWidthAndHeight({ target: wrapperDiv })
      setIsReady(true)
    }, 100)
  })

  useEffect(() => {
    return () => {
      if (throttleRef.current) {
        clearTimeout(throttleRef.current)
      }
    }
  }, [])

  function onDocumentLoadSuccess(pdf): void {
    const { numPages }: { numPages: number } = pdf
    setTotalPages(numPages)
  }

  useEffect(() => {
    setPageNumber(1)
    setTotalPages(0)
    setIsReady(false)
    setIsPreviousButtonDisabled(true)
    setIsNextButtonDisabled(false)
  }, [attachmentUrl])

  useEffect(() => {
    setIsPreviousButtonDisabled(pageNumber === 1)
  }, [pageNumber])

  useEffect(() => {
    setIsNextButtonDisabled(pageNumber === totalPages)
  }, [pageNumber, totalPages])

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const goToNextPage = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  return (
    <div className='flex flex-col items-stretch justify-center flex-auto'>
      <div
        ref={wrapperDiv}
        className='relative flex-auto flex justify-center items-center min-h-56'
      >
        <div ref={pageDiv} style={{ opacity: isReady ? 1 : 0 }}>
          <Document file={attachmentUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={width}
              height={height}
            />
          </Document>
        </div>

        {isReady && totalPages > 1 ? (
          <div className='box-border bg-slate-200'>
            <div className='absolute left-0 right-0 bottom-8 flex items-center p-4 justify-center mt-4 space-x-4'>
              <div className='bg-white p-2 rounded shadow-md flex items-center space-x-4'>
                <button
                  onClick={goToPreviousPage}
                  data-qa-name='button-pdfPagePreviousArrow'
                  disabled={pageNumber <= 1}
                  className={`
                    ${customArrowButtonStyles} ${
                      isPreviousButtonDisabled
                        ? customArrowButtonDisabledStyles
                        : customArrowButtonActiveStyles
                    }
                          `}
                >
                  <IoChevronBack />
                </button>

                <span className='text-sm font-medium'>
                  {$localize`:common.words|Common Words - Number of Total@@commonWordsNumberOfTotal:${pageNumber}:item: of ${totalPages}:total:`}
                </span>

                <button
                  onClick={goToNextPage}
                  data-qa-name='button-pdfPageNextArrow'
                  disabled={pageNumber >= totalPages}
                  className={`
                  ${customArrowButtonStyles} ${
                    isNextButtonDisabled
                      ? customArrowButtonDisabledStyles
                      : customArrowButtonActiveStyles
                  }
                        `}
                >
                  <IoChevronForward />
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PDFPreview
