import * as React from 'react'
import { FunctionComponent, useEffect, useState, useContext } from 'react'
import * as Ariakit from '@ariakit/react'
import { FaClipboardCheck as CopiedToClipboardIcon } from 'react-icons/fa'
import {
  IoCloseSharp as CloseIcon,
  IoArrowBackOutline as BackIcon
} from 'react-icons/io5'

import VideoPlayer from '../video-player/video-player'
import VideoPlayerModalDownloadOptions from './video-player-modal-download-options'

import ResourceList from '../resource-list'
import {
  ComponentWrapperContext,
  VideoPlayerModalContext,
  VideoPlayerModalShareUrlContext
} from '../context'
import SensitiveContentWarning from '../sensitive-content-warning'

const VideoPlayerModalComponent: FunctionComponent = () => {
  const {
    id,
    url,
    shareUrl: initialShareUrl,
    title,
    parentVideoTitle,
    tag,
    description,
    provider,
    relatedDocuments,
    backToParentVideo,
    isSensitiveContent
  } = useContext(VideoPlayerModalContext)
  const { unmount } = useContext(ComponentWrapperContext)
  const { shareUrl } = useContext(VideoPlayerModalShareUrlContext)

  const [resolvedShareUrl, setResolvedShareUrl] = useState(initialShareUrl)
  const [copiedVisibility, setCopiedVisibility] = useState(false)
  const [isParentVideo, setIsParentVideo] = useState(true)
  const [
    isSensitiveContentWarningVisible,
    setIsSensitiveContentWarningVisible
  ] = useState(isSensitiveContent)

  const dialog = Ariakit.useDialogStore()
  const toggleVisibility = () => {
    dialog.hide()
    unmount()
  }

  useEffect(() => {
    dialog.show()
  }, [])

  useEffect(() => {
    setResolvedShareUrl(shareUrl ? shareUrl : initialShareUrl)
  }, [shareUrl])

  useEffect(() => {
    if (copiedVisibility) {
      setTimeout(() => {
        setCopiedVisibility(false)
      }, 2000)
    }
  }, [copiedVisibility])

  useEffect(() => {
    if (!backToParentVideo) {
      setIsParentVideo(true)
    } else {
      setIsParentVideo(false)
    }
  }, [backToParentVideo])

  useEffect(() => {
    setIsSensitiveContentWarningVisible(isSensitiveContent)
  }, [id, url])

  const copyToClipboard = () => {
    document.execCommand('copy')
    setCopiedVisibility(true)
    //  add video type checking here

    if (navigator.clipboard) {
      navigator.clipboard.writeText(resolvedShareUrl).catch((err) => {
        console.error('Failed to copy: ', err)
      })
    }
  }

  const handleDismiss = (event) => {
    if (event.button === 0) {
      dialog.hide()
      unmount()
    }
  }

  return (
    <Ariakit.Dialog
      store={dialog}
      hideOnInteractOutside={false}
      backdrop={
        <div
          className='fixed inset-0 bg-black opacity-50'
          onMouseDown={handleDismiss}
        />
      }
      className='fixed z-50 inset-3 w-5/6 max-w-screen-lg overflow-y-auto text-black inset-y-[5vh] mx-auto flex justify-stretch react-video-modal'
    >
      <div className='w-full relative'>
        <div
          className='cursor-pointer absolute z-10 right-0 top-0 md:right-6 md:top-6 lg:right-12 lg:top-12 p-2 text-white'
          onClick={toggleVisibility}
          data-qa-name='close-videoModalExit'
        >
          <CloseIcon className='fill-white text-3xl' />
        </div>

        <div className='bg-white p-0 md:p-6 lg:p-10 flex flex-col gap-4 md:gap-6'>
          <VideoPlayer
            {...{
              provider,
              url,
              id
            }}
          />
          <div className='bg-white flex flex-col justify-between lg:flex-row gap-6 sm:gap-8'>
            <div className='flex-2 flex flex-col gap-4 justify-between min-w-0 px-6 md:px-0'>
              <div className='flex flex-col gap-2'>
                {!isParentVideo ? (
                  <a
                    className='flex gap-1 items-center font-semibold text-alpha-navy hover:text-alpha-navy-140 transition-colors leading-8 cursor-pointer'
                    onClick={backToParentVideo}
                    data-qa-name='link-backToParentVideo'
                  >
                    <BackIcon className='text-xl' />
                    <span>{parentVideoTitle}</span>
                  </a>
                ) : null}

                <small className='font-semibold text-alpha-black'>{tag}</small>
                <h1 className='my-0 leading-8'>{title}</h1>
                {description ? (
                  <p className='my-0 text-base font-body leading-normal'>
                    {description}
                  </p>
                ) : null}
              </div>

              <div className='flex flex-col gap-2'>
                <label className='font-bold'>
                  {$localize`:videoPlayerModal.label|Share this video label@@ShareThisVideoLabel:Share this video`}
                </label>
                <div className='items-center justify-between pl-2 py-2 flex gap-3 border-solid border-alpha-oatmeal-10 border rounded'>
                  <p
                    className='m-0 text-ellipsis whitespace-nowrap overflow-hidden'
                    data-qa-name='text-videoShareUrl'
                  >
                    {resolvedShareUrl}
                  </p>
                  <div className='flex flex-none gap-2 px-2 items-center relative'>
                    {copiedVisibility ? (
                      <span className='absolute bg-slate-100 px-2 py-1 text-green-800 flex gap-2 right-full items-center rounded-lg'>
                        {$localize`:common.words|Copied@@commonWordCopied:Copied`}{' '}
                        <CopiedToClipboardIcon />
                      </span>
                    ) : null}
                    <div
                      role='button'
                      className='justify-center bg-black text-white py-1 px-3.5 font-medium rounded-full cursor-pointer'
                      onClick={copyToClipboard}
                      data-qa-name='button-copyVideoUrl'
                    >
                      {$localize`:common.words|Copy@@commonWordCopy:Copy`}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <VideoPlayerModalDownloadOptions />
          </div>
          {isSensitiveContentWarningVisible ? (
            <div className='p-6 pt-0 md:p-0 empty:hidden'>
              <SensitiveContentWarning
                hide={() => setIsSensitiveContentWarningVisible(false)}
              />
            </div>
          ) : null}
        </div>
        {relatedDocuments && relatedDocuments.length != 0 ? (
          <div className='lg:p-10 p-6 flex flex-col bg-[#EFEBE9]'>
            <ResourceList
              title={$localize`:resourceList.title|ResourceListTitle@@resourceListTitle:Resources For This Session`}
              resources={relatedDocuments}
            ></ResourceList>
          </div>
        ) : null}
      </div>
    </Ariakit.Dialog>
  )
}

export default VideoPlayerModalComponent
