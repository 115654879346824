import * as React from 'react'
import { useEffect, useRef, FunctionComponent } from 'react'
import Player from '@vimeo/player'
interface VimeoVideoPlayerProps {
  // videoId: string
  url?: string
  onPlay?: () => void
  onProgress?: (data: { secondsElapsed?: number; percentage?: number }) => void
}

const VimeoVideoPlayer: FunctionComponent<VimeoVideoPlayerProps> = ({
  url,
  onPlay,
  onProgress
}) => {
  const playerRef = useRef<Player | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  // Method to create the Vimeo player
  const createPlayer = () => {
    if (!containerRef.current || !url) return

    const player = new Player(containerRef.current, {
      url: url,
      autoplay: false,
    });

    playerRef.current = player

    player
      .ready()
      .then(() => {
        // Set the iframe dimensions
        const iframe = containerRef.current!.querySelector('iframe');
        if (iframe) {
          iframe.style.width = '100%';  // Set the width
          iframe.style.height = '100%'; // Set the height
        }

        // Now that the dimensions are set, make the player visible
        containerRef.current!.style.visibility = 'visible';
      })
      .catch((err) => {
        console.error('Error initializing Vimeo player', err);
      });


    // Add player event listeners
    player.on('play', () => {
      if (onPlay) onPlay()
    })

    player.on('timeupdate', (progressData) => {
      const progressPercentage =
        (progressData.seconds / progressData.duration) * 100
      if (onProgress) onProgress(progressData)
    })
  }

  useEffect(() => {
    createPlayer()

    // Cleanup function to destroy the player on unmount
    return () => {
      if (playerRef.current) {
        playerRef.current.destroy()
        playerRef.current = null
      }
    }
  }, [url])

  return (
    <div
      ref={containerRef}
      className="aspect-video w-full"
      style={{ visibility: 'hidden' }} // Initially hidden until the player is ready
    />
  )
}

export default VimeoVideoPlayer
